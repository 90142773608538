import { BaseApi, IBaseApiModel } from "./baseApi";
import { IClassBooking, ITournamentBooking } from "utils/interface";

interface IClassBookingModel extends IBaseApiModel<IClassBooking> {}

export function ClassBookingApi(): IClassBookingModel {
  const path = "classbooking";
  const { get, getDetails, create, update, remove } = BaseApi<IClassBooking>({
    controllerPath: path,
  });

  return {
    get,
    getDetails,
    create,
    update,
    remove,
  };
}
