import React from "react";
import {
  Space,
  Typography,
  DatePicker,
  Select,
  Input,
  Table,
  Tag,
  Divider,
  notification,
  Popconfirm,
  TimePicker,
  Button,
  Grid,
} from "antd";
import Column from "antd/es/table/Column";
import { CheckOutlined, DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { IFieldBooking } from "utils/interface";
import { FieldBookingApi } from "api/fieldBookingApi";
import dayjs from "dayjs";
import { disabledDate, disabledTime, utcToLocal } from "utils/helpers";
import { SearchOutlined } from "@ant-design/icons";

function ManageBooking() {
  const [data, setData] = React.useState<IFieldBooking[]>([]);
  const [editingId, setEditingId] = React.useState<number | null>(null);
  const [availableFields, setAvailableFields] = React.useState<string[]>([]);
  const [originalData, setOriginalData] = React.useState<IFieldBooking[]>([]);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let searchInput: any;
  const screens = Grid.useBreakpoint();

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await FieldBookingApi().get();
      setData(response);
    };
    fetchData();
  }, []);

  const onConfirmClick = async (record: IFieldBooking) => {
    if (record.fieldNumber === undefined || record.fieldNumber === null) {
      notification.error({
        message: "Chưa chọn sân",
      });
      return;
    } else {
      const validFields = await FieldBookingApi().getAvailableFields(record);
      if (validFields.includes(record.fieldNumber!.toString())) {
        record.isConfirmed = true;
        try {
          await FieldBookingApi().update(record);
          notification.success({
            message: "Xác nhận thành công",
            description: `Đã xác nhận đặt sân của ${record.name} - ${record.phoneNumber}`,
          });

          const response = await FieldBookingApi().get();
          setData(response);
        } catch (e) {
          notification.error({
            message: "Xác nhận thất bại",
          });
        }
      } else {
        notification.error({
          message: "Sân đã được đặt",
        });
      }
    }
  };

  const onDeleteClick = async (id: number) => {
    try {
      await FieldBookingApi().remove(id);
      notification.success({
        message: "Xóa thành công",
      });

      const response = await FieldBookingApi().get();
      setData(response);
    } catch (e) {
      notification.error({
        message: "Xóa thất bại",
      });
    }
  };

  const onSaveClick = async (record: IFieldBooking) => {
    if (
      (record.fieldNumber === undefined || record.fieldNumber === null) &&
      record.isConfirmed
    ) {
      notification.error({
        message: "Chưa chọn sân",
      });
      return;
    }

    try {
      await FieldBookingApi().update(record);
      notification.success({
        message: "Chỉnh sửa thành công",
      });

      const response = await FieldBookingApi().get();
      setData(response);
      setEditingId(null);
    } catch (e) {
      notification.error({
        message: "Chỉnh sửa thất bại",
      });
    }
  };

  const getAvailableFields = async (newBooking: IFieldBooking) => {
    const response = await FieldBookingApi().getAvailableFields(newBooking);
    console.log(response);
    setAvailableFields(response);
  };

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: any,
    placeholder: any,
    isDateColumn: boolean = false
  ) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        {isDateColumn ? (
          <DatePicker
            style={{ marginBottom: 8, display: "block" }}
            value={selectedKeys[0]}
            onChange={(date) => setSelectedKeys(date ? [date] : [])}
            placeholder={placeholder}
            format="DD-MM-YYYY"
          />
        ) : (
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            placeholder={placeholder}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        )}
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Lọc
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Xóa bộ lọc
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) => {
      if (isDateColumn) {
        const recordDate = dayjs(record[dataIndex]);
        return recordDate.isSame(value, "day");
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible && searchInput) {
        setTimeout(() => searchInput.select());
      }
    },
  });

  return (
    <Space
      direction="vertical"
      style={{
        padding: screens.xl ? "2rem 6rem" : "2rem",
        background: "white",
        height: "100%",
        width: "100%",
        overflow: "scroll",
      }}
    >
      <Typography.Title level={2}>Quản lý đặt sân</Typography.Title>

      <Table dataSource={data}>
        <Column
          title="Người đặt"
          dataIndex="name"
          key="name"
          {...getColumnSearchProps("name", "Tìm người đặt")}
          render={(name, record: IFieldBooking) => {
            if (editingId === record.id) {
              return (
                <Input
                  value={name}
                  onChange={(e) =>
                    setData(
                      data.map((item) =>
                        item.id === record.id
                          ? { ...item, name: e.target.value }
                          : item
                      )
                    )
                  }
                />
              );
            }
            return name;
          }}
        />

        <Column
          title="Số điện thoại"
          dataIndex="phoneNumber"
          key="phoneNumber"
          {...getColumnSearchProps("phoneNumber", "Tìm số điện thoại")}
        />

        <Column
          title="Ngày đặt"
          dataIndex="bookingTime"
          key="bookingDate"
          sorter={(a: IFieldBooking, b: IFieldBooking) =>
            new Date(a.bookingTime).getTime() -
            new Date(b.bookingTime).getTime()
          }
          {...getColumnSearchProps("bookingTime", "Tìm ngày đặt", true)}
          render={(bookingTime, record: IFieldBooking) => {
            if (editingId === record.id) {
              return (
                <DatePicker
                  format="DD-MM-YYYY"
                  value={utcToLocal(bookingTime)}
                  disabledDate={disabledDate}
                  onChange={(date) => {
                    if (date) {
                      const newBookingDate = utcToLocal(bookingTime).toDate();
                      // Set the year, month, and day of newBookingDate to the selected date
                      newBookingDate.setFullYear(date.year());
                      newBookingDate.setMonth(date.month());
                      newBookingDate.setDate(date.date());

                      getAvailableFields({
                        ...record,
                        bookingTime: newBookingDate,
                      });

                      setData(
                        data.map((item) =>
                          item.id === record.id
                            ? {
                                ...item,
                                bookingTime: newBookingDate,
                                fieldNumber: undefined,
                              }
                            : item
                        )
                      );
                    }
                  }}
                />
              );
            }
            return utcToLocal(bookingTime).format("DD-MM-YYYY");
          }}
        />

        <Column
          title="Giờ đặt"
          dataIndex="bookingTime"
          key="bookingTime"
          render={(bookingTime, record: IFieldBooking) => {
            if (editingId === record.id) {
              return (
                <TimePicker
                  format="HH:mm"
                  placeholder="Chọn thời gian"
                  hideDisabledOptions
                  disabledTime={disabledTime}
                  value={utcToLocal(bookingTime)}
                  onChange={(time) => {
                    if (time) {
                      const newBookingTime = utcToLocal(bookingTime).toDate();
                      // Set the hours and minutes of newBookingTime to the selected time
                      newBookingTime.setHours(time.hour());
                      newBookingTime.setMinutes(time.minute());

                      getAvailableFields({
                        ...record,
                        bookingTime: newBookingTime,
                      });

                      setData(
                        data.map((item) =>
                          item.id === record.id
                            ? {
                                ...item,
                                bookingTime: newBookingTime,
                                fieldNumber: undefined,
                              }
                            : item
                        )
                      );
                    }
                  }}
                />
              );
            }
            return utcToLocal(bookingTime).format("HH:mm");
          }}
        />

        <Column
          title="Thời gian đá"
          dataIndex="duration"
          key="duration"
          render={(duration, record: IFieldBooking) => {
            if (editingId === record.id) {
              return (
                <Select
                  placeholder="Chọn thời gian"
                  value={duration}
                  onChange={(value) => {
                    getAvailableFields({
                      ...record,
                      duration: value,
                    });

                    setData(
                      data.map((item) =>
                        item.id === record.id
                          ? { ...item, duration: value, fieldNumber: undefined }
                          : item
                      )
                    );
                  }}
                >
                  <Select.Option value={60}>1 tiếng</Select.Option>
                  <Select.Option value={1.5 * 60}>
                    1 tiếng 30 phút
                  </Select.Option>
                  <Select.Option value={2 * 60}>2 tiếng</Select.Option>
                </Select>
              );
            }
            return (
              <Tag color="blue">
                {dayjs("00:00:00", "HH:mm:ss")
                  .add(duration, "minute")
                  .format("H:mm")}
              </Tag>
            );
          }}
        />
        <Column
          title="Sân"
          dataIndex="fieldNumber"
          key="fieldNumber"
          render={(fieldNumber, record: IFieldBooking) => {
            if (editingId === record.id) {
              return (
                <Select
                  placeholder="Chọn sân"
                  value={fieldNumber ? fieldNumber.toString() : null}
                  onChange={(value) =>
                    setData(
                      data.map((item) =>
                        item.id === record.id
                          ? { ...item, fieldNumber: value }
                          : item
                      )
                    )
                  }
                >
                  {availableFields.map((fieldNumber) => (
                    <Select.Option key={fieldNumber} value={fieldNumber}>
                      Sân {fieldNumber}
                    </Select.Option>
                  ))}
                </Select>
              );
            }
            return fieldNumber;
          }}
        />

        <Column
          title="Trạng thái"
          dataIndex="isConfirmed"
          key="isConfirmed"
          sorter={(a: IFieldBooking, b: IFieldBooking) =>
            Number(a.isConfirmed) - Number(b.isConfirmed)
          }
          filters={[
            { text: "Đã xác nhận", value: true },
            { text: "Chưa xác nhận", value: false },
          ]}
          onFilter={(value, record) => record.isConfirmed === value}
          render={(isConfirmed) => {
            return (
              <>
                {isConfirmed ? (
                  <Tag color="green">Đã xác nhận</Tag>
                ) : (
                  <Tag color="purple">Chưa xác nhận</Tag>
                )}
              </>
            );
          }}
        />
        <Column
          title="Hành động"
          dataIndex="operation"
          key="operation"
          render={(_: any, record: IFieldBooking) => {
            if (editingId === record.id) {
              return (
                <Space>
                  <Typography.Link onClick={() => onSaveClick(record)}>
                    Lưu
                  </Typography.Link>
                  <Divider type="vertical" />
                  <Typography.Link
                    onClick={() => {
                      setData(originalData); // Restore the original data
                      setEditingId(null);
                    }}
                  >
                    Hủy
                  </Typography.Link>
                </Space>
              );
            } else {
              return (
                <Space>
                  {!record.isConfirmed && (
                    <>
                      <Typography.Link onClick={() => onConfirmClick(record)}>
                        <CheckOutlined />
                      </Typography.Link>
                      <Divider type="vertical" />
                    </>
                  )}
                  <Typography.Link
                    onClick={() => {
                      setEditingId(record.id!);
                      getAvailableFields(record);
                      setOriginalData([...data]); // Save a copy of the original data
                    }}
                  >
                    <FormOutlined />
                  </Typography.Link>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="Xác nhận xóa?"
                    onConfirm={() => onDeleteClick(record.id!)}
                    cancelText="Hủy"
                    okText="Xóa"
                  >
                    <Typography.Link>
                      <DeleteOutlined />
                    </Typography.Link>
                  </Popconfirm>
                </Space>
              );
            }
          }}
        />
      </Table>
    </Space>
  );
}

export default ManageBooking;
