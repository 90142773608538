import React from "react";
import { Grid, Space, Typography } from "antd";
import {} from "@ant-design/icons";
import { FootballTexture } from "assets";

const { Title, Text } = Typography;

function Address() {
  const screens = Grid.useBreakpoint();
  return (
    <Space
      id="address"
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "3rem",
        padding: "3rem 2rem",
        backgroundColor: "#161623",
        overflow: "hidden",
      }}
    >
      <Typography>
        <Title
          style={{
            color: "#f5f5f5",
          }}
        >
          Địa chỉ
        </Title>
      </Typography>
      <Text style={{ fontSize: "1rem", color: "#f5f5f5", textAlign: "center" }}>
        Địa chỉ: 70C Đường số 6, Cư xá Chu Văn An, Phường 26, Quận Bình Thạnh,
        TP.HCM
      </Text>
      <div className="google-map-code">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d823.8623744197847!2d106.70755800415198!3d10.81345537802013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDQ4JzQ3LjgiTiAxMDbCsDQyJzI3LjkiRQ!5e0!3m2!1svi!2s!4v1697001607848!5m2!1svi!2s"
          width={screens.md ? 800 : 400}
          height={screens.md ? 600 : 300}
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
        ></iframe>
      </div>

      <div className="giving-direction-video">
        <iframe
          src="https://drive.google.com/file/d/10y00bl0iP9opkGT-SGJJ-zfRcgAheA4n/preview"
          width={screens.md ? 800 : 400}
          height={screens.md ? 600 : 300}
          allow="autoplay"
          allowFullScreen
          style={{
            border: 0,
          }}
        ></iframe>
      </div>
      <img
        className="ball-texture"
        src={FootballTexture}
        style={{
          position: "absolute",
          top: "-7%",
          left: "-7%",
          width: "25%",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />

      <img
        className="ball-texture"
        src={FootballTexture}
        style={{
          position: "absolute",
          width: "20%",
          bottom: "-7%",
          right: "-5%",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
    </Space>
  );
}

export default Address;
