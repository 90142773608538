import React from "react";
import { Button, Form, Input, Typography } from "antd";
import { Link } from "react-router-dom";

interface IForgotPasswordFormProps {
  onFinish: (values: { email: string }) => void;
  onFinishFailed: (errorInfo: any) => void;
}

function ForgotPasswordForm({
  onFinish,
  onFinishFailed,
}: IForgotPasswordFormProps) {
  return (
    <Form
      name="basic"
      layout="vertical"
      style={{ width: 300 }}
      requiredMark={false}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            type: "email",
            message: "Địa chỉ email không hợp lệ!",
          },
          { required: true, message: "Vui lòng nhập email!" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Gửi lại mật khẩu
        </Button>
      </Form.Item>

      <Form.Item>
        Quay về đăng nhập?{" "}
        <Typography.Link>
          <Link to="/login">Đăng nhập</Link>
        </Typography.Link>
      </Form.Item>
    </Form>
  );
}

export default ForgotPasswordForm;
