import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import AppRoutes from "routes/AppRoutes";
import { AuthProvider } from "contexts/AuthContext";

const router = createBrowserRouter(AppRoutes);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
