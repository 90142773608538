import React from "react";
import { RouteObject } from "react-router-dom";
import {
  Login,
  Landing,
  Register,
  ForgotPassword,
  MainApp,
  BookingField,
  ManageBooking,
  BookingClass,
  Tournament,
  ManageClass,
  ManageTournament,
} from "pages";
import { PrivateRoute } from "./PrivateRoute";

const AppRoutes: RouteObject[] = [
  {
    index: true,
    element: <Landing />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "register",
    element: <Register />,
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "app",
    element: <PrivateRoute element={<MainApp />} />,
    children: [
      {
        index: true,
        element: <BookingField />,
      },
      {
        path: "class",
        element: <BookingClass />,
      },
      {
        path: "tournament",
        element: <Tournament />,
      },
      {
        path: "manage-bookings",
        element: <PrivateRoute element={<ManageBooking />} requiredAdmin />,
      },
      {
        path: "manage-class",
        element: <PrivateRoute element={<ManageClass />} requiredAdmin />,
      },
      {
        path: "manage-tournament",
        element: <PrivateRoute element={<ManageTournament />} requiredAdmin />,
      },
    ],
  },
];

export default AppRoutes;
