import React from "react";
import { Grid, Layout, Menu, Space, Typography, theme } from "antd";
import { NavLink } from "react-router-dom";

const { Footer } = Layout;
const { Text } = Typography;

const navLinks = [
  {
    title: "Trang chủ",
    path: "#intro",
  },
  {
    title: "Dịch vụ",
    path: "#service",
  },
  {
    title: "Liên hệ",
    path: "#contact",
  },
  {
    title: "Đăng nhập",
    path: "/login",
  },
];

function FooterBar() {
  const screens = Grid.useBreakpoint();
  return (
    <Footer
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "2rem",
        padding: "2rem 0",
        overflow: "hidden",
      }}
    >
      <Menu
        theme="light"
        mode="horizontal"
        style={{
          borderBottom: "none",
          background: "#f5f5f5",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {navLinks.map((link) => (
          <Menu.Item
            key={link.path}
            style={{ margin: screens.sm ? "0 2.5rem" : "0" }}
          >
            <NavLink to={link.path}>{link.title}</NavLink>
          </Menu.Item>
        ))}
      </Menu>
      <Space direction="vertical" style={{ textAlign: "center" }}>
        <Text type="secondary">Bình Thạnh, Tp.HCM, Việt Nam</Text>
        <Text type="secondary">
          ©2023 sanbongkhoakhoi. All rights reserved.
        </Text>
      </Space>
    </Footer>
  );
}

export default FooterBar;
