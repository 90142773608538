import React from "react";
import {
  Grid,
  Layout,
  Menu,
  Space,
  Typography,
  Modal,
  Form,
  Input,
  notification,
} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LogoutOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
import { useAuth } from "contexts/AuthContext";
import { Logo } from "assets";
import { IChangePasswordModel } from "utils/interface";

interface SideBarProps {
  menu: { title: string; icon: React.ReactElement; path: string }[];
}

function Sidebar({ menu }: SideBarProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const screens = Grid.useBreakpoint();
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [form] = Form.useForm<IChangePasswordModel>();

  const handleMenuClick = (path: string) => {
    navigate(path);
  };

  const showChangePasswordModal = async () => {
    Modal.confirm({
      title: "Đổi mật khẩu",
      content: (
        <Form form={form} layout="vertical">
          <Form.Item
            label="Mật khẩu hiện tại"
            name="password"
            rules={[
              { required: true, message: "Vui lòng nhập mật khẩu hiện tại" },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Mật khẩu mới"
            name="newPassword"
            rules={[{ required: true, message: "Vui lòng nhập mật khẩu mới" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Xác nhận mật khẩu mới"
            name="confirmNewPassword"
            rules={[
              { required: true, message: "Vui lòng xác nhận mật khẩu mới" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Mật khẩu xác nhận không khớp")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      ),
      cancelText: "Hủy",
      async onOk() {
        if (!form.isFieldsTouched()) {
          // No fields have been touched, so the form is not valid
          return false;
        }

        const errors = form.getFieldsError();
        const hasErrors = errors.some((field) => field.errors.length > 0);

        if (hasErrors) {
          // There are errors in the form, so it's not valid
          return false;
        }
        try {
          const values = await form.validateFields();
          const model: IChangePasswordModel = {
            password: values.password,
            newPassword: values.newPassword,
          };
          await auth.changePassword(model);
          notification.success({
            message: "Đổi mật khẩu thành công",
            description: "Bạn có thể đăng nhập lại bằng mật khẩu mới!",
          });
        } catch (error: any) {
          notification.error({
            message: "Đổi mật khẩu thất bại",
            description: "Đã có lỗi xảy ra, vui lòng thử lại!",
          });
        }
      },
    });
  };

  return (
    <Layout.Sider
      theme="light"
      width={250}
      breakpoint="md"
      collapsedWidth={screens.xs ? 0 : 80}
      onCollapse={(collapsed) => setIsCollapsed(collapsed)}
    >
      <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
        style={{
          fontSize: "1rem",
          padding: "1rem",
          height: "100%",
          overflow: "hidden",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          className="logo"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
          }}
        >
          <Link to="/" style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={Logo}
              alt="logo"
              width={screens.md ? "100px" : "100%"}
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Link>
        </div>
        {menu.map((item) => (
          <Menu.Item
            key={item.path}
            icon={item.icon}
            onClick={() => handleMenuClick(item.path)}
          >
            {item.title}
          </Menu.Item>
        ))}
      </Menu>

      {!isCollapsed && (
        <Menu
          mode="inline"
          selectedKeys={[location.pathname]}
          style={{
            fontSize: "1rem",
            padding: "1rem",
            position: "absolute",
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Menu.Item key="user" icon={<UserOutlined />}>
            <Typography.Link
              style={{
                color: "#1677ff",
              }}
            >
              {auth.userInfor?.email}
            </Typography.Link>
          </Menu.Item>
          <Menu.Item
            key="change-password"
            icon={<LockOutlined />}
            onClick={showChangePasswordModal}
          >
            Đổi mật khẩu
          </Menu.Item>
          <Menu.Item
            key="logout"
            icon={<LogoutOutlined />}
            onClick={() => auth.logout()}
          >
            Đăng xuất
          </Menu.Item>
        </Menu>
      )}
    </Layout.Sider>
  );
}

export default Sidebar;
