import React from "react";
import { Space, Typography } from "antd";
import { PhoneFilled } from "@ant-design/icons";

const { Title, Text } = Typography;

function Contact() {
  return (
    <Space
      id="contact"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        gap: "1.5rem",
        padding: "2rem 0 7rem 0",
      }}
    >
      <Typography>
        <Title>Liên hệ</Title>
      </Typography>

      <Space direction="vertical">
        <Space
          size="large"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.5rem",
          }}
        >
          <PhoneFilled />
          <Text
            style={{
              fontSize: "1.5rem",
            }}
          >
            0938 784 194
          </Text>
        </Space>
        <Space
          size="large"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1.5rem",
          }}
        >
          <PhoneFilled />
          <Text
            style={{
              fontSize: "1.5rem",
            }}
          >
            0902 842 642
          </Text>
        </Space>
      </Space>
    </Space>
  );
}

export default Contact;
