import { BaseApi, IBaseApiModel } from "./baseApi";
import { ITournamentBooking } from "utils/interface";

interface ITournamentBookingModel extends IBaseApiModel<ITournamentBooking> {}

export function TournamentBookingApi(): ITournamentBookingModel {
  const path = "tournamentbooking";
  const { get, getDetails, create, update, remove } =
    BaseApi<ITournamentBooking>({
      controllerPath: path,
    });

  return {
    get,
    getDetails,
    create,
    update,
    remove,
  };
}
