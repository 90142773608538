import React from "react";
import { Layout, Typography } from "antd";
import { Sidebar } from "components";
import {
  AppstoreAddOutlined,
  SolutionOutlined,
  AppstoreOutlined,
  ContainerOutlined,
  SkinOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { Outlet, useLocation } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";

function MainAppLayout() {
  const auth = useAuth();
  const isAdmin = auth.userInfor?.isAdmin;
  const commonMenu = [
    {
      title: "Đặt sân",
      icon: <AppstoreAddOutlined style={{ fontSize: "1.2rem" }} />,
      path: "/app",
    },
    {
      title: "Đăng ký lớp học",
      icon: <SolutionOutlined style={{ fontSize: "1.2rem" }} />,
      path: "/app/class",
    },
    {
      title: "Tổ chức giải đấu",
      icon: <SkinOutlined style={{ fontSize: "1.2rem" }} />,
      path: "/app/tournament",
    },
  ];

  const adminMenu = [
    ...commonMenu,
    {
      title: "Quản lý đặt sân",
      icon: <AppstoreOutlined style={{ fontSize: "1.2rem" }} />,
      path: "/app/manage-bookings",
    },
    {
      title: "Quản lý lớp học",
      icon: <ContainerOutlined style={{ fontSize: "1.2rem" }} />,
      path: "/app/manage-class",
    },
    {
      title: "Quản lý giải đấu",
      icon: <StarOutlined style={{ fontSize: "1.2rem" }} />,
      path: "/app/manage-tournament",
    },
  ];

  return (
    <Layout
      style={{
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <Sidebar menu={isAdmin ? adminMenu : commonMenu} />

      <Layout>
        <Layout.Content>
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default MainAppLayout;
