import React from "react";
import {
  Space,
  Typography,
  DatePicker,
  Select,
  Input,
  Button,
  Card,
  Form,
  Table,
  Tag,
  Divider,
  notification,
  Popconfirm,
  Grid,
} from "antd";
import {
  CheckOutlined,
  DeleteOutlined,
  FormOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Column from "antd/es/table/Column";
import { ITournamentBooking } from "utils/interface";
import dayjs from "dayjs";
import { TournamentBookingApi } from "api/tournamentBookingApi";
import { disabledDate, utcToLocal } from "utils/helpers";

function ManageTournament() {
  const [data, setData] = React.useState<ITournamentBooking[]>([]);
  const [editingId, setEditingId] = React.useState<number | null>(null);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let searchInput: any;
  const screens = Grid.useBreakpoint();

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await TournamentBookingApi().get();
      setData(response);
    };
    fetchData();
  }, []);

  const onConfirmClick = async (record: ITournamentBooking) => {
    record.isConfirmed = true;
    try {
      await TournamentBookingApi().update(record);
      notification.success({
        message: "Xác nhận thành công",
        description: `Đã xác nhận yêu cầu tạo giải đấu của ${record.name} - ${record.phoneNumber}`,
      });

      const response = await TournamentBookingApi().get();
      setData(response);
    } catch (e) {
      notification.error({
        message: "Xác nhận thất bại",
      });
    }
  };

  const onDeleteClick = async (id: number) => {
    try {
      await TournamentBookingApi().remove(id);
      notification.success({
        message: "Xóa thành công",
      });

      const response = await TournamentBookingApi().get();
      setData(response);
    } catch (e) {
      notification.error({
        message: "Xóa thất bại",
      });
    }
  };

  const onSaveClick = async (record: ITournamentBooking) => {
    try {
      await TournamentBookingApi().update(record);
      notification.success({
        message: "Chỉnh sửa thành công",
      });

      const response = await TournamentBookingApi().get();
      setData(response);
      setEditingId(null);
    } catch (e) {
      notification.error({
        message: "Chỉnh sửa thất bại",
      });
    }
  };
  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: any,
    placeholder: any,
    isDateColumn: boolean = false
  ) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div style={{ padding: 8 }}>
        {isDateColumn ? (
          <DatePicker
            style={{ marginBottom: 8, display: "block" }}
            value={selectedKeys[0]}
            onChange={(date) => setSelectedKeys(date ? [date] : [])}
            placeholder={placeholder}
            format="DD-MM-YYYY"
          />
        ) : (
          <Input
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            placeholder={placeholder}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        )}
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Lọc
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Xóa bộ lọc
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) => {
      if (isDateColumn) {
        const recordDate = dayjs(record[dataIndex]);
        return recordDate.isSame(value, "day");
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible && searchInput) {
        setTimeout(() => searchInput.select());
      }
    },
  });

  return (
    <Space
      direction="vertical"
      style={{
        padding: screens.xl ? "2rem 6rem" : "2rem",
        background: "white",
        height: "100%",
        width: "100%",
        overflow: "scroll",
      }}
    >
      <Typography.Title level={2}>Quản lý giải đấu</Typography.Title>

      <Table dataSource={data}>
        <Column
          title="Người đặt"
          dataIndex="name"
          key="name"
          {...getColumnSearchProps("name", "Tìm người đặt")}
          render={(name, record: ITournamentBooking) => {
            if (editingId === record.id) {
              return (
                <Input
                  value={name}
                  onChange={(e) =>
                    setData(
                      data.map((item) =>
                        item.id === record.id
                          ? { ...item, name: e.target.value }
                          : item
                      )
                    )
                  }
                />
              );
            }
            return name;
          }}
        />

        <Column
          title="Số điện thoại"
          dataIndex="phoneNumber"
          key="phoneNumber"
          {...getColumnSearchProps("phoneNumber", "Tìm số điện thoại")}
        />

        <Column
          title="Ngày bắt đầu"
          dataIndex="startDate"
          key="startDate"
          sorter={(a: ITournamentBooking, b: ITournamentBooking) =>
            new Date(a.startDate!).getTime() - new Date(b.startDate!).getTime()
          }
          render={(startDate, record: ITournamentBooking) => {
            if (editingId === record.id) {
              return (
                <DatePicker
                  format="DD-MM-YYYY"
                  value={utcToLocal(startDate)}
                  disabledDate={disabledDate}
                  onChange={(date) => {
                    if (date) {
                      setData(
                        data.map((item) =>
                          item.id === record.id
                            ? { ...item, startDate: date.toDate() }
                            : item
                        )
                      );
                    }
                  }}
                />
              );
            }
            return utcToLocal(startDate).format("DD-MM-YYYY");
          }}
        />
        <Column
          title="Ngày kết thúc"
          dataIndex="endDate"
          key="endDate"
          sorter={(a: ITournamentBooking, b: ITournamentBooking) =>
            new Date(a.endDate!).getTime() - new Date(b.endDate!).getTime()
          }
          render={(endDate, record: ITournamentBooking) => {
            if (editingId === record.id) {
              return (
                <DatePicker
                  format="DD-MM-YYYY"
                  value={utcToLocal(endDate)}
                  disabledDate={disabledDate}
                  onChange={(date) => {
                    if (date) {
                      setData(
                        data.map((item) =>
                          item.id === record.id
                            ? { ...item, endDate: date.toDate() }
                            : item
                        )
                      );
                    }
                  }}
                />
              );
            }
            return utcToLocal(endDate).format("DD-MM-YYYY");
          }}
        />

        <Column
          title="Thời gian"
          dataIndex="period"
          key="period"
          render={(period, record: ITournamentBooking) => {
            if (editingId === record.id) {
              return (
                <Select
                  placeholder="Chọn thời gian"
                  value={period}
                  onChange={(value) =>
                    setData(
                      data.map((item) =>
                        item.id === record.id
                          ? { ...item, period: value }
                          : item
                      )
                    )
                  }
                >
                  <Select.Option value="Sáng">Buổi sáng</Select.Option>
                  <Select.Option value="Chiều">Buổi chiều</Select.Option>
                  <Select.Option value="Cả ngày">Cả ngày</Select.Option>
                </Select>
              );
            }
            return period;
          }}
        />

        <Column
          title="Trạng thái"
          dataIndex="isConfirmed"
          key="isConfirmed"
          sorter={(a: ITournamentBooking, b: ITournamentBooking) =>
            Number(a.isConfirmed) - Number(b.isConfirmed)
          }
          filters={[
            { text: "Đã xác nhận", value: true },
            { text: "Chưa xác nhận", value: false },
          ]}
          onFilter={(value, record) => record.isConfirmed === value}
          render={(isConfirmed) => {
            return (
              <>
                {isConfirmed ? (
                  <Tag color="green">Đã xác nhận</Tag>
                ) : (
                  <Tag color="purple">Chưa xác nhận</Tag>
                )}
              </>
            );
          }}
        />
        <Column
          title="Hành động"
          dataIndex="operation"
          key="operation"
          render={(_: any, record: ITournamentBooking) => {
            if (editingId === record.id) {
              return (
                <Space>
                  <Typography.Link onClick={() => onSaveClick(record)}>
                    Lưu
                  </Typography.Link>
                  <Divider type="vertical" />
                  <Typography.Link onClick={() => setEditingId(null)}>
                    Hủy
                  </Typography.Link>
                </Space>
              );
            } else {
              return (
                <Space>
                  {!record.isConfirmed && (
                    <>
                      <Typography.Link onClick={() => onConfirmClick(record)}>
                        <CheckOutlined />
                      </Typography.Link>
                      <Divider type="vertical" />
                    </>
                  )}
                  <Typography.Link onClick={() => setEditingId(record.id!)}>
                    <FormOutlined />
                  </Typography.Link>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="Xác nhận xóa?"
                    onConfirm={() => onDeleteClick(record.id!)}
                    cancelText="Hủy"
                    okText="Xóa"
                  >
                    <Typography.Link>
                      <DeleteOutlined />
                    </Typography.Link>
                  </Popconfirm>
                </Space>
              );
            }
          }}
        />
      </Table>
    </Space>
  );
}

export default ManageTournament;
