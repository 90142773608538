import React from "react";
import { Button, Form, Input, Typography } from "antd";
import { Link } from "react-router-dom";
import { IAuthModel } from "utils/interface";

interface ILoginFormProps {
  onFinish: (values: IAuthModel) => void;
  onFinishFailed: (errorInfo: any) => void;
}

function LoginForm({ onFinish, onFinishFailed }: ILoginFormProps) {
  return (
    <Form
      name="basic"
      layout="vertical"
      style={{ width: 300 }}
      requiredMark={false}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item<IAuthModel>
        label="Email"
        name="email"
        rules={[
          {
            type: "email",
            message: "Địa chỉ email không hợp lệ!",
          },
          { required: true, message: "Vui lòng nhập email!" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item<IAuthModel>
        label="Mật khẩu"
        name="password"
        rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Đăng nhập
        </Button>
      </Form.Item>

      <Form.Item className="login-nav">
        <Typography.Link>
          <Link to="/register">Đăng ký</Link>
        </Typography.Link>

        <Typography.Link
          style={{
            float: "right",
          }}
        >
          <Link to="/forgot-password">Quên mật khẩu?</Link>
        </Typography.Link>
      </Form.Item>
    </Form>
  );
}

export default LoginForm;
