import React from "react";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Typography,
  Image,
  Modal,
  notification,
  Grid,
} from "antd";
import { IClassBooking, IFieldBooking } from "utils/interface";
import { useAuth } from "contexts/AuthContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ClassBookingApi } from "api/classBookingApi";
dayjs.extend(customParseFormat);

function BookingClass() {
  const auth = useAuth();
  const screens = Grid.useBreakpoint();

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    notification.error({
      message: "Đăng ký lớp thất bại",
      description: "Đã có lỗi xảy ra, vui lòng thử lại!",
    });
  };

  const showConfirmModal = (values: IClassBooking) => {
    Modal.confirm({
      title: "Xác nhận thông tin đặt lớp",
      content: (
        <Space direction="vertical">
          <p>
            <b>Lớp: </b> {values.class || "Chưa nhập"}
          </p>
          <p>
            <b>Khóa: </b> {values.time || "Chưa nhập"}
          </p>
          <p>
            <b>Tên người đặt:</b> {values.name || "Chưa nhập"}
          </p>
          <p>
            <b>Số điện thoại:</b> {values.phoneNumber || "Chưa nhập"}
          </p>
        </Space>
      ),
      async onOk() {
        try {
          await ClassBookingApi().create({
            name: values.name,
            phoneNumber: values.phoneNumber,
            class: values.class,
            time: values.time,
            bookerId: auth.userInfor?.id!,
          });
          console.log("Booking success");

          Modal.success({
            title: "Đăng ký lớp thành công",
            content: (
              <>
                <p>
                  Quý khách đã đăng ký lớp học thành công, sẽ có nhân viên liên
                  hệ với quý khách trong thời gian sớm nhất để xác nhận! Quý
                  khách vui lòng thanh toán trực tiếp tại văn phòng hoặc chuyển
                  khoản vào tài khoản của CLB bóng đá Khoa Khôi. Trân trọng cảm
                  ơn.
                </p>
                <p>
                  <b>Ngân hàng VP Bank</b>
                </p>
                <p>
                  <b>Số tài khoản:</b> 1965107637
                </p>
                <p>
                  <b>Chủ tài khoản:</b> Nguyễn Thường Hiếu
                </p>
                <p>
                  <b>Nội dung chuyển khoản:</b> Đăng ký lớp - {values.name} -{" "}
                  {values.phoneNumber}
                </p>
              </>
            ),
            width: 600,
          });
        } catch {
          notification.error({
            message: "Đăng ký lớp thất bại",
            description: "Đã có lỗi xảy ra, vui lòng thử lại!",
          });
          return console.log("Oops errors!");
        }
      },
    });
  };

  return (
    <Space
      direction="vertical"
      style={{
        padding: screens.xl ? "2rem 10rem" : "2.4rem",
        background: "white",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography.Title level={2}>Đăng ký lớp học</Typography.Title>

      <Space
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          flexWrap: "wrap",
        }}
      >
        <Form
          name="booking-class-form"
          layout="vertical"
          requiredMark={false}
          onFinish={showConfirmModal}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="class"
            label="Chọn lớp"
            rules={[{ required: true, message: "Vui lòng chọn lớp!" }]}
          >
            <Select placeholder="Chọn lớp">
              <Select.Option value="T7">Lớp T7</Select.Option>
              <Select.Option value="CN">Lớp CN</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="time"
            label="Chọn khóa"
            rules={[{ required: true, message: "Vui lòng chọn khóa!" }]}
          >
            <Select placeholder="Chọn khóa">
              <Select.Option value="7h30">7h30-9h</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="name"
            label="Tên"
            rules={[{ required: true, message: "Vui lòng nhập tên!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Số điện thoại"
            rules={[
              {
                pattern: new RegExp(/(0[3|5|7|8|9])+([0-9]{8})\b/g),
                message: "Số điện thoại không hợp lệ!",
              },
              { required: true, message: "Vui lòng nhập số điện thoại!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Đăng ký lớp
            </Button>
          </Form.Item>
        </Form>

        <Card
          title="Thông tin lớp học"
          style={{ width: screens.lg ? 700 : "auto" }}
        >
          <Card.Grid style={{ width: screens.lg ? "50%" : "100%" }}>
            <iframe
              title="field-video"
              src="https://drive.google.com/file/d/1yZs8oNYFvVjMgFLuoYEuMtHZg0oLeBlK/preview"
              width="100%"
              height="100%"
              allow="autoplay"
              allowFullScreen
              style={{
                border: 0,
              }}
            ></iframe>
          </Card.Grid>
          <Card.Grid style={{ width: screens.lg ? "50%" : "100%" }}>
            <iframe
              title="field-video"
              src="https://drive.google.com/file/d/1ZtrOeuRRgMEiQxjV19VQkECcqd2PuXkx/preview"
              width="100%"
              height="100%"
              allow="autoplay"
              allowFullScreen
              style={{
                border: 0,
              }}
            ></iframe>
          </Card.Grid>
          <Card.Grid style={{ width: screens.lg ? "50%" : "100%" }}>
            <iframe
              title="field-video"
              src="https://drive.google.com/file/d/1ExMRdZgH9Ii-w6SF8hKO-Ko0D4a6kdvf/preview"
              width="100%"
              height="100%"
              allow="autoplay"
              allowFullScreen
              style={{
                border: 0,
              }}
            ></iframe>
          </Card.Grid>
          <Card.Grid style={{ width: screens.lg ? "50%" : "100%" }}>
            <iframe
              title="field-video"
              src="https://drive.google.com/file/d/1_x1OD77kRsyesAtghsGOpBhBAp90SNLN/preview"
              width="100%"
              height="100%"
              allow="autoplay"
              allowFullScreen
              style={{
                border: 0,
              }}
            ></iframe>
          </Card.Grid>
        </Card>
      </Space>
    </Space>
  );
}

export default BookingClass;
