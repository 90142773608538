import React from "react";
import { Modal, Space } from "antd";
import { ForgotPasswordForm } from "components";
import { AuthenticationLayout } from "layouts";
import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";

function ForgotPassword() {
  const auth = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (auth.userInfor && auth.checkTokenExpiration()) {
      navigate("/app");
    }
  }, [auth, navigate]);

  const onFinish = async (values: { email: string }) => {
    try {
      await auth.forgotPassword(values.email);
      Modal.success({
        title: "Gửi yêu cầu thành công",
        content: "Vui lòng kiểm tra email để lấy lại mật khẩu",
        okText: "Quay về trang đăng nhập",
        onOk: () => navigate("/login"),
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <AuthenticationLayout>
      <ForgotPasswordForm onFinish={onFinish} onFinishFailed={onFinishFailed} />
    </AuthenticationLayout>
  );
}

export default ForgotPassword;
