import React from "react";
import { LoginForm } from "components";
import { AuthenticationLayout } from "layouts";
import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { IAuthModel } from "utils/interface";
import { notification } from "antd";

function Login() {
  const auth = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (auth.userInfor && auth.checkTokenExpiration()) {
      navigate("/app");
    }
  }, [auth, navigate]);

  const onFinish = async (values: IAuthModel) => {
    const authModel: IAuthModel = {
      email: values.email,
      password: values.password,
    };

    try {
      await auth.login(authModel);
      navigate("/app");
    } catch (error: any) {
      console.log(error);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    notification.error({
      message: "Đăng nhập thất bại",
      description: "Thông tin đăng nhập không chính xác",
    });
  };

  return (
    <AuthenticationLayout>
      <LoginForm onFinish={onFinish} onFinishFailed={onFinishFailed} />
    </AuthenticationLayout>
  );
}

export default Login;
