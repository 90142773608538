import { useAuth } from "contexts/AuthContext";
import React, { useEffect } from "react";
import { Route, useNavigate } from "react-router-dom";

interface PrivateRouteProps {
  requiredAdmin?: boolean;
  element: React.ReactNode;
}

export function PrivateRoute({
  requiredAdmin = false,
  element,
}: PrivateRouteProps) {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !auth.checkTokenExpiration() ||
      auth.userInfor === undefined ||
      auth.userInfor === null
    ) {
      auth.logout();
      navigate("/login");
    } else if (requiredAdmin && auth.userInfor.isAdmin === false) {
      navigate("/app");
    }
  }, [auth, navigate, requiredAdmin]);

  if (
    !auth.checkTokenExpiration() ||
    auth.userInfor === undefined ||
    auth.userInfor === null ||
    (requiredAdmin && auth.userInfor.isAdmin === false)
  ) {
    navigate("/login");
  }

  return <React.Fragment>{element}</React.Fragment>;
}
