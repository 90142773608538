import React from "react";
import { Button, Modal, Result, Space } from "antd";
import { RegisterForm } from "components";
import { AuthenticationLayout } from "layouts";
import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { IAuthModel } from "utils/interface";

function Register() {
  const auth = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (auth.userInfor && auth.checkTokenExpiration()) {
      navigate("/app");
    }
  }, [auth, navigate]);

  const onFinish = async (values: IAuthModel) => {
    const authModel: IAuthModel = {
      email: values.email,
      password: values.password,
    };

    try {
      await auth.register(authModel);
      Modal.success({
        title: "Đăng ký thành công",
        content: "Vui lòng đăng nhập để tiếp tục",
        okText: "Đăng nhập",
        onOk: () => navigate("/login"),
      });
    } catch (error: any) {
      Modal.error({
        title: "Đăng ký thất bại",
        content: error.message,
        okText: "Đóng",
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <AuthenticationLayout>
      <RegisterForm onFinish={onFinish} onFinishFailed={onFinishFailed} />
    </AuthenticationLayout>
  );
}

export default Register;
