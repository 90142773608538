import api from "./apiConfig";
import { BaseApi, IBaseApiModel } from "./baseApi";
import { IFieldBooking } from "utils/interface";

interface IFieldBookingApiModel extends IBaseApiModel<IFieldBooking> {
  validate(newBooking: IFieldBooking): Promise<boolean>;
  getAvailableFields(newBooking: IFieldBooking): Promise<string[]>;
}

export function FieldBookingApi(): IFieldBookingApiModel {
  const path = "fieldbooking";
  const { get, getDetails, create, update, remove } = BaseApi<IFieldBooking>({
    controllerPath: path,
  });

  async function validate(newBooking: IFieldBooking) {
    const response = await api.post(`${path}/validate`, newBooking);
    return response.data;
  }

  async function getAvailableFields(newBooking: IFieldBooking) {
    const response = await api.post(`${path}/get-available-fields`, newBooking);
    return response.data;
  }

  return {
    get,
    getDetails,
    create,
    update,
    remove,
    validate,
    getAvailableFields,
  };
}
