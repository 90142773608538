import axios from "axios";
import { notification } from "antd";
import { CREDENTIAL_LOCALSTORAGE_KEY } from "utils/constant";

const api = axios.create({
  // baseURL: "https://khoakhoiapi.azurewebsites.net/api/",
  // baseURL: "http://localhost:5000/api/",
  // baseURL: "https://localhost:44335/api/",
  // baseURL: "http://193.203.163.241:5000/api/",
  baseURL: "/server/api/",
  timeout: 30000,
});

api.interceptors.request.use(
  (config) => {
    const credential = localStorage.getItem(CREDENTIAL_LOCALSTORAGE_KEY);
    const accessToken = credential ? JSON.parse(credential).accessToken : null;
    console.log("New request version Bard");

    // If the token is present, set it on the Authorization header
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    // if (!error.status || error.request) {
    //   notification.error({
    //     message: "Có lỗi xảy ra!",
    //     description: "Không thể kết nối đến server",
    //   });
    // }
    return Promise.reject(error);
  }
);

export default api;
