import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export function utcToLocal(utcDate: string) {
  return dayjs.utc(utcDate).local();
}

export const disabledDate = (currentDate: any) => {
  // Disable all dates before today
  if (currentDate.isBefore(dayjs(), "day")) {
    return true;
  }

  // Disable all Thursdays
  if (currentDate.day() === 4) {
    return true;
  }

  return false;
};

export const disabledTime = (selectedDate: any) => {
  if (!selectedDate) {
    return {};
  }

  const dayOfWeek = selectedDate.day();
  const hours = Array.from({ length: 24 }, (_, i) => i);

  let disabledHours: any[] = [];
  switch (dayOfWeek) {
    case 0: // Sunday
      disabledHours = hours.filter(
        (hour) => hour < 7 || (hour >= 9 && hour < 19) || hour > 19
      );
      break;
    case 1: // Monday
      disabledHours = hours.filter(
        (hour) => hour < 19 || (hour >= 21 && hour < 22)
      );
      break;
    case 2: // Tuesday
      disabledHours = hours.filter(
        (hour) => hour < 17 || (hour >= 19 && hour < 22)
      );
      break;
    case 3: // Wednesday
      disabledHours = hours.filter((hour) => hour < 17 || hour > 19);
      break;
    case 4: // Thursday
      disabledHours = hours; // No available fields
      break;
    case 5: // Friday
      disabledHours = hours.filter(
        (hour) => hour < 17 || (hour >= 22 && hour < 23)
      );
      break;
    case 6: // Saturday
      disabledHours = hours.filter(
        (hour) => hour < 7 || (hour >= 9 && hour < 19)
      );
      break;
    default:
      disabledHours = hours;
  }

  return {
    disabledHours: () => disabledHours,
    disabledMinutes: (selectedHour: any) => {
      let minutes = Array.from({ length: 60 }, (_, i) => i);
      // On all other days and times, only allow minutes 0 and 30
      return minutes.filter((minute) => minute % 30 !== 0);
    },
  };
};
