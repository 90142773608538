import React from "react";
import { Space } from "antd";
import { Link } from "react-router-dom";
import { Logo } from "assets";

type AuthenticationLayoutProps = {
  children: React.ReactNode;
};

function AuthenticationLayout({ children }: AuthenticationLayoutProps) {
  return (
    <Space
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "2rem",
      }}
    >
      <Link to="/" style={{ marginRight: "25px" }}>
        <img src={Logo} alt="logo" width="150px" />
      </Link>
      {children}
    </Space>
  );
}

export default AuthenticationLayout;
