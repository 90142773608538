import React from "react";
import { AuthApi, IAuthApiModel } from "api/authApi";

export interface IAuthContextModel extends IAuthApiModel {}

const defaultAuthContextValue: IAuthContextModel = {
  login: async () => {},
  logout: () => {},
  register: async () => {},
  forgotPassword: async () => {},
  changePassword: async () => {},
  checkTokenExpiration: () => false,
};

export const AuthContext = React.createContext(defaultAuthContextValue);

export function AuthProvider({ children }: any) {
  const {
    userInfor,
    register,
    login,
    logout,
    forgotPassword,
    changePassword,
    checkTokenExpiration,
  } = AuthApi();

  return (
    <AuthContext.Provider
      value={{
        userInfor,
        register,
        login,
        logout,
        forgotPassword,
        changePassword,
        checkTokenExpiration,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => React.useContext(AuthContext);
