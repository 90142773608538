import React from "react";
import { Button, Form, Input, Typography } from "antd";
import { Link } from "react-router-dom";

interface IRegisterFormProps {
  onFinish: (values: any) => void;
  onFinishFailed: (errorInfo: any) => void;
}

function RegisterForm({ onFinish, onFinishFailed }: IRegisterFormProps) {
  return (
    <Form
      name="basic"
      layout="vertical"
      style={{ width: 300 }}
      requiredMark={false}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            type: "email",
            message: "Địa chỉ email không hợp lệ!",
          },
          { required: true, message: "Vui lòng nhập email!" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Mật khẩu"
        name="password"
        hasFeedback
        rules={[{ required: true, message: "Vui lòng nhập mật khẩu!" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label="Xác nhận mật khẩu"
        name="confirm-password"
        dependencies={["password"]}
        hasFeedback
        rules={[
          { required: true, message: "Vui lòng nhập lại mật khẩu!" },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("Mật khẩu xác nhận không khớp với mật khẩu")
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Đăng ký
        </Button>
      </Form.Item>

      <Form.Item>
        Đã có tài khoản?{" "}
        <Typography.Link>
          <Link to="/login">Đăng nhập</Link>
        </Typography.Link>
      </Form.Item>
    </Form>
  );
}

export default RegisterForm;
