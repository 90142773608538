// AuthService.tsx
import React from "react";
import api from "./apiConfig";
import {
  ICurrentUser,
  IAuthModel,
  IChangePasswordModel,
} from "utils/interface";
import { CREDENTIAL_LOCALSTORAGE_KEY } from "utils/constant";
import { useNavigate } from "react-router-dom";
import { Modal, notification } from "antd";

export interface IAuthApiModel {
  userInfor?: ICurrentUser;
  register: (model: IAuthModel) => Promise<void>;
  login: (model: IAuthModel) => Promise<void>;
  logout: () => void;
  forgotPassword: (email: string) => Promise<void>;
  changePassword: (model: IChangePasswordModel) => Promise<void>;
  checkTokenExpiration: () => boolean;
}

export function AuthApi(): IAuthApiModel {
  const controllerPath = "auth";
  const [userInfor, setUserInforState] = React.useState<
    ICurrentUser | undefined
  >(getUserInforFromStorage());

  function setUserInfor(userInfoData?: ICurrentUser) {
    saveUserInforToStorage(userInfoData);
    setUserInforState(userInfoData);
  }

  async function register(model: IAuthModel) {
    try {
      const response = await api.post(`${controllerPath}/register`, model);
      return response.data.data;
    } catch (error) {
      // console.error(error);
      // return undefined;
      throw error;
    }
  }

  async function login(model: IAuthModel) {
    try {
      const response = await api.post(`${controllerPath}/login`, model);
      setUserInfor(response.data.data);
      return response.data.data;
    } catch (error: any) {
      console.log(error);
      notification.error({
        message: "Đăng nhập thất bại",
        description: error.response.data.message,
      });

      throw error;
    }
  }

  function logout() {
    setUserInfor(undefined);
  }

  async function forgotPassword(email: string) {
    try {
      const response = await api.post(
        `${controllerPath}/forgot-password/${email}`
      );
      return response.data.data;
    } catch (error: any) {
      console.error(error);
      notification.error({
        message: "Có lỗi xảy ra",
        description: error.response.data.message,
      });

      throw error;
    }
  }

  async function changePassword(model: IChangePasswordModel) {
    try {
      const response = await api.post(
        `${controllerPath}/change-password`,
        model
      );
      return response.data.data;
    } catch (error: any) {
      console.error(error);

      throw error;
    }
  }

  function checkTokenExpiration() {
    if (userInfor) {
      const { expiresIn, requestAt } = userInfor;
      const requestAtDate = new Date(requestAt);
      const expiresAt = new Date(requestAtDate.getTime() + expiresIn * 1000);
      return expiresAt.getTime() > Date.now();
    }
    return false;
  }

  return {
    userInfor,
    register,
    login,
    logout,
    forgotPassword,
    changePassword,
    checkTokenExpiration,
  };
}

function getUserInforFromStorage(): ICurrentUser | undefined {
  const credential = localStorage.getItem(CREDENTIAL_LOCALSTORAGE_KEY);

  return credential ? JSON.parse(credential) : undefined;
}

function saveUserInforToStorage(infor: ICurrentUser | undefined) {
  infor
    ? localStorage.setItem(CREDENTIAL_LOCALSTORAGE_KEY, JSON.stringify(infor))
    : localStorage.removeItem(CREDENTIAL_LOCALSTORAGE_KEY);
}
