import React from "react";
import { Button, Drawer, Grid, Image, Layout, Menu } from "antd";
import { NavLink } from "react-router-dom";
import logo from "assets/logo.png";
import { MenuOutlined } from "@ant-design/icons";

const { Header } = Layout;

const navLinks = [
  {
    title: "Trang chủ",
    path: "/",
  },
  {
    title: "Dịch vụ",
    path: "/#service",
  },
  {
    title: "Liên hệ",
    path: "/#contact",
  },
  {
    title: "Đăng ký",
    path: "/register",
  },
  {
    title: "Đăng nhập",
    path: "/login",
  },
];

function HeaderBar() {
  const screens = Grid.useBreakpoint();
  const [drawerVisible, setDrawerVisible] = React.useState(false);
  return (
    <Header
      style={{
        height: "auto",
        background: "white",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      <img
        src={logo}
        alt="Logo"
        style={{
          height: "82px",
          padding: screens.md ? "0 2.5rem" : "0",
          objectFit: "cover",
          objectPosition: "center",
        }}
      />

      {screens.md ? (
        <Menu
          theme="light"
          mode="horizontal"
          defaultSelectedKeys={["/"]}
          // selectedKeys={[location.pathname]}
          style={{
            background: "white",
            marginLeft: "auto",
          }}
        >
          {navLinks.map((link) => (
            <Menu.Item key={link.path}>
              <NavLink to={link.path}>{link.title}</NavLink>
            </Menu.Item>
          ))}
        </Menu>
      ) : (
        <>
          <Button
            type="default"
            onClick={() => setDrawerVisible((prev) => !prev)}
          >
            <MenuOutlined />
          </Button>
          <Drawer
            title="Menu"
            placement="right"
            closable={false}
            onClose={() => setDrawerVisible((prev) => !prev)}
            visible={drawerVisible}
            size="large"
          >
            <Menu
              theme="light"
              mode="vertical"
              defaultSelectedKeys={["/"]}
              // selectedKeys={[location.pathname]}
              style={{ width: "100%" }}
            >
              {navLinks.map((link) => (
                <Menu.Item key={link.path}>
                  <NavLink
                    to={link.path}
                    onClick={() => setDrawerVisible(false)}
                  >
                    {link.title}
                  </NavLink>
                </Menu.Item>
              ))}
            </Menu>
          </Drawer>
        </>
      )}
    </Header>
  );
}

export default HeaderBar;
