import React from "react";
import {
  Space,
  Image,
  Typography,
  Button,
  ConfigProvider,
  Carousel,
  Row,
  Grid,
} from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { Img1, Img2, Img3, Img4, Img5, Img6, Img7 } from "assets";
const { Title, Paragraph, Text } = Typography;

const pictureData = [Img1, Img2, Img3, Img4, Img5, Img6, Img7];

function Picture() {
  const screens = Grid.useBreakpoint();

  const settings = {
    dots: true,
    slidesToShow: screens.md ? 3 : 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <RightOutlined style={{ background: "red" }} />,
    prevArrow: <LeftOutlined />,
    className: "carousel",
    arrows: true,
  };

  return (
    <div
      id="picture"
      style={{
        textAlign: "center",
        padding: "3rem",
        width: "100%",
      }}
    >
      <Typography
        style={{
          marginBottom: "5rem",
        }}
      >
        <Title>Hình ảnh</Title>
      </Typography>

      <div>
        <Carousel {...settings}>
          {pictureData.map((src, index) => (
            <Image
              src={src}
              height={300}
              alt={`slide ${index + 1}`}
              loading="lazy"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
              preview={screens.xs ? false : true}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Picture;
