import React from "react";
import { Button, Space, Typography, Image, Grid } from "antd";
import { CheckOutlined, FlagOutlined, StarOutlined } from "@ant-design/icons";
import { Clas, Img4, Img6 } from "assets";
import { Link } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;

const serviceData = [
  {
    title: "Đặt sân",
    description: "Dễ dàng đặt sân bóng trực tuyến",
    icon: <CheckOutlined />,
    path: "app",
    image: Img4,
    introduction:
      "Bạn đang tìm kiếm một sân bóng mini chất lượng, tiện nghi và giá cả hợp lý? Hãy đến với sân bóng Khoa Khôi, nơi bạn có thể thỏa sức đá bóng cùng bạn bè, gia đình hoặc đồng nghiệp. Sân bóng Khoa Khôi có diện tích rộng rãi, mặt sân cỏ nhân tạo cao cấp, hệ thống chiếu sáng hiện đại, phù hợp cho mọi lứa tuổi và trình độ chơi bóng. Hãy nhanh tay liên hệ với chúng tôi để không bỏ lỡ cơ hội trải nghiệm tuyệt vời này!",
  },
  {
    title: "Lớp học",
    description: "Đăng kí lớp học rèn luyện thể thao cho trẻ nhỏ",
    icon: <FlagOutlined />,
    path: "app/class",
    image: Clas,
    introduction:
      "Sân bóng Khoa Khôi cung cấp các lớp học bóng đá cho trẻ em. Với đội ngũ huấn luyện viên giàu kinh nghiệm, chúng tôi cam kết sẽ mang đến cho các bé những giờ học bổ ích, vui vẻ và đầy hứng khởi. Hãy đến với chúng tôi để các bé được rèn luyện thể chất, tăng cường sức đề kháng và phát triển tư duy, kỹ năng xã hội.",
  },
  {
    title: "Tổ chức giải đấu",
    description: "Tổ chức các giải đấu thường niên",
    icon: <StarOutlined />,
    path: "app/tournament",
    image: Img6,
    introduction:
      "Các giải đấu thường niên của sân bóng Khoa Khôi luôn thu hút được sự quan tâm của đông đảo các đội bóng trong và ngoài khu vực. Với hệ thống sân bóng hiện đại, đội ngũ nhân viên chuyên nghiệp, chúng tôi cam kết sẽ mang đến cho các bạn những giải đấu chất lượng, hấp dẫn và đầy kịch tính.",
  },
];

function Service() {
  const [isSelected, setIsSelected] = React.useState<number>(0);
  const screens = Grid.useBreakpoint();

  return (
    <Space
      id="service"
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: screens.md ? "7rem" : "2rem",
        padding: "5rem 2rem",
        backgroundColor: "#161623",
      }}
    >
      <Space
        style={{
          textAlign: "center",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "start",
          justifyContent: "center",
          gap: "4rem",
          minWidth: "100vw",
        }}
      >
        {serviceData.map((item, index) => (
          <div className="services__card">
            <div className="services__card__content">
              <div className="card__icon">{item.icon}</div>
              <Text className="card__title">{item.title}</Text>
              <Paragraph className="card__description" type="secondary">
                {item.description}
              </Paragraph>
              <a href="#infor">
                <Button
                  className="card__btn"
                  size="large"
                  onClick={() => {
                    setIsSelected(index);
                  }}
                >
                  Tìm hiểu
                </Button>
              </a>
            </div>
          </div>
        ))}
      </Space>

      <Space
        size="large"
        style={{
          display: "flex",
          flexWrap: "wrap",
          margin: "auto ",
          justifyContent: "center",
        }}
      >
        <Space id="infor">
          <Typography>
            <Title style={{ color: "#f5f5f5" }}>
              {serviceData[isSelected].title}
            </Title>
            <Paragraph
              style={{
                fontSize: "1.1rem",
                maxWidth: "650px",
                color: "#f5f5f5",
              }}
            >
              {serviceData[isSelected].introduction}
            </Paragraph>

            <Link to={serviceData[isSelected].path}>
              <Button
                type="primary"
                shape="round"
                size="large"
                style={{
                  background: "#f5f5f5",
                  color: "#161623",
                  fontWeight: 600,
                }}
              >
                Đặt ngay
              </Button>
            </Link>
          </Typography>
        </Space>

        <Image
          width={screens.md ? 700 : "100%"}
          src={serviceData[isSelected].image}
        />
      </Space>
    </Space>
  );
}

export default Service;
