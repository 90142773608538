import React from "react";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Typography,
  Image,
  Modal,
  notification,
  Grid,
} from "antd";
import { CheckCircleTwoTone, PhoneFilled } from "@ant-design/icons";
import { IFieldBooking } from "utils/interface";
import { useAuth } from "contexts/AuthContext";
import { FieldBookingApi } from "api/fieldBookingApi";
import { Img1, Img2, Img3, Img4 } from "assets";
import locale from "antd/es/date-picker/locale/vi_VN";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { disabledDate, disabledTime } from "utils/helpers";
dayjs.extend(customParseFormat);

function BookingField() {
  const auth = useAuth();
  const screens = Grid.useBreakpoint();

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    notification.error({
      message: "Đặt sân thất bại",
      description: "Vui lòng thử lại!",
    });
  };

  const showConfirmModal = (values: IFieldBooking) => {
    Modal.confirm({
      title: "Xác nhận thông tin đặt sân",
      content: (
        <Space direction="vertical">
          <p>
            <b>Ngày đặt: </b>{" "}
            {dayjs(values.bookingTime).format("DD-MM-YYYY") || "Chưa nhập"}
          </p>
          <p>
            <b>Giờ đặt: </b>{" "}
            {dayjs(values.bookingTime).format("HH:mm") || "Chưa nhập"}
          </p>
          <p>
            <b>Thời gian đá:</b>{" "}
            {values.duration
              ? dayjs("00:00:00", "HH:mm:ss")
                  .add(values.duration, "minute")
                  .format("H:mm")
              : "Chưa nhập"}
          </p>
          <p>
            <b>Tên người đặt:</b> {values.name || "Chưa nhập"}
          </p>
          <p>
            <b>Số điện thoại:</b> {values.phoneNumber || "Chưa nhập"}
          </p>
        </Space>
      ),
      cancelText: "Hủy",
      async onOk() {
        try {
          const newBooking: IFieldBooking = {
            name: values.name,
            phoneNumber: values.phoneNumber,
            bookingTime: values.bookingTime,
            duration: values.duration,
            bookerId: auth.userInfor?.id!,
          };
          const validation = await FieldBookingApi().validate(newBooking);
          console.log(validation);

          if (validation) {
            await FieldBookingApi().create(newBooking);
            Modal.success({
              title: "Đặt sân thành công",
              content: (
                <>
                  <p>
                    Quý khách đã đặt sân thành công, sẽ có nhân viên liên hệ với
                    quý khách trong thời gian sớm nhất để xác nhận! Quý khách
                    vui lòng thanh toán trực tiếp tại văn phòng hoặc chuyển
                    khoản vào tài khoản của CLB bóng đá Khoa Khôi. Trân trọng
                    cảm ơn.
                  </p>
                  <p>
                    <b>Ngân hàng VP Bank</b>
                  </p>
                  <p>
                    <b>Số tài khoản:</b> 1965107637
                  </p>
                  <p>
                    <b>Chủ tài khoản:</b> Nguyễn Thường Hiếu
                  </p>
                  <p>
                    <b>Nội dung chuyển khoản:</b> Đặt sân - {values.name} -{" "}
                    {values.phoneNumber}
                  </p>
                </>
              ),
              width: 600,
            });
          } else {
            Modal.error({
              title: "Đặt sân thất bại",
              content:
                "Không còn sân trống để đặt vào thời gian này, vui lòng chọn thời gian khác!",
            });
          }
        } catch {
          notification.error({
            message: "Đặt sân thất bại",
            description: "Đã có lỗi xảy ra, vui lòng thử lại!",
          });
          return console.log("Oops errors!");
        }
      },
    });
  };

  return (
    <Space
      direction="vertical"
      style={{
        padding: screens.xl ? "2rem 10rem" : "2.4rem",
        background: "white",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography.Title level={2}>Đặt sân</Typography.Title>

      <Space
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          flexWrap: "wrap",
        }}
      >
        <Form
          name="booking-field-form"
          layout="vertical"
          requiredMark={false}
          onFinish={showConfirmModal}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="bookingTime"
            label="Ngày giờ đặt"
            rules={[{ required: true, message: "Vui lòng chọn ngày giờ!" }]}
          >
            <DatePicker
              format="DD-MM-YYYY (HH:mm)"
              showTime={{
                defaultValue: dayjs("00:00:00", "HH:mm:ss"),
                hideDisabledOptions: true,
              }}
              locale={locale}
              disabledDate={disabledDate}
              disabledTime={disabledTime}
            />
          </Form.Item>
          <Form.Item
            name="duration"
            label="Thời gian đá"
            rules={[{ required: true, message: "Vui lòng chọn thời gian đá!" }]}
          >
            <Select placeholder="Chọn khoảng thời gian">
              <Select.Option value={60}>1 tiếng</Select.Option>
              <Select.Option value={1.5 * 60}>1 tiếng 30 phút</Select.Option>
              <Select.Option value={2 * 60}>2 tiếng</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="name"
            label="Tên người đặt"
            rules={[
              { required: true, message: "Vui lòng nhập tên người đặt!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Số điện thoại"
            rules={[
              {
                pattern: new RegExp(/(0[3|5|7|8|9])+([0-9]{8})\b/g),
                message: "Số điện thoại không hợp lệ!",
              },
              { required: true, message: "Vui lòng nhập số điện thoại!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: screens.xs ? "100%" : "auto",
              }}
            >
              Đặt sân
            </Button>
          </Form.Item>
        </Form>

        <Card
          title="Thông tin sân"
          style={{ width: screens.lg ? 700 : "auto" }}
        >
          <Card.Grid style={{ width: screens.lg ? "50%" : "100%" }}>
            <Image src={Img1} />
          </Card.Grid>
          <Card.Grid style={{ width: screens.lg ? "50%" : "100%" }}>
            <Image src={Img2} />
          </Card.Grid>
          <Card.Grid style={{ width: screens.lg ? "50%" : "100%" }}>
            <Image src={Img3} />
          </Card.Grid>
          <Card.Grid style={{ width: screens.lg ? "50%" : "100%" }}>
            <iframe
              title="field-video"
              src="https://drive.google.com/file/d/1M9xMCs_90BMI1jct41gaE1tx-OYnUtvI/preview"
              width="100%"
              height="100%"
              allow="autoplay"
              allowFullScreen
              style={{
                border: 0,
              }}
            ></iframe>
          </Card.Grid>
          <Card.Grid style={{ width: "50%" }}>
            <b>Wifi: </b>
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          </Card.Grid>
          <Card.Grid style={{ width: "50%" }}>
            <b>Canteen: </b>
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          </Card.Grid>
        </Card>
      </Space>
      <div
        className="float-btn"
        style={{
          position: "fixed",
          top: "10vh",
          right: "25px",
          zIndex: 100,
          width: "fit-content",
          padding: "5px",
          background: "#2B2D42",
          color: "white",
          borderRadius: "5px",
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <PhoneFilled
          style={{
            fontSize: "1rem",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          <span>Liên hệ</span>
          <span
            style={{
              fontSize: "1.1rem",
            }}
          >
            0938784194
          </span>
        </div>
      </div>
    </Space>
  );
}

export default BookingField;
