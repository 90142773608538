import React from "react";
import { ConfigProvider, FloatButton, Grid, Layout } from "antd";
import {
  Address,
  Contact,
  FooterBar,
  HeaderBar,
  Intro,
  Picture,
  Service,
} from "components";
import { useLocation } from "react-router-dom";
import { PhoneFilled } from "@ant-design/icons";

const { Content } = Layout;
const { useBreakpoint } = Grid;

function LandingPage() {
  const location = useLocation();
  const screens = useBreakpoint();

  React.useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <Layout>
      <HeaderBar />
      <Content
        style={{
          position: "relative",
          background: "white",
          overflow: "hidden",
        }}
      >
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorPrimary: "#2B2D42",
                algorithm: true,
              },
            },
          }}
        >
          <div
            className="float-btn"
            style={{
              position: "fixed",
              top: "20vh",
              right: "25px",
              zIndex: 100,
              width: "fit-content",
              padding: "5px",
              background: "#2B2D42",
              color: "white",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <PhoneFilled
              style={{
                fontSize: "1rem",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <span>Liên hệ</span>
              <span
                style={{
                  fontSize: "1.1rem",
                }}
              >
                0938784194
              </span>
            </div>
          </div>

          <Intro />
          <Service />
          <Picture />
          <Contact />
          <Address />
        </ConfigProvider>
      </Content>
      <FooterBar />
    </Layout>
  );
}

export default LandingPage;
