import React from "react";
import { Space, Image, Typography, Button, Row, Col, Grid } from "antd";
import IntroImg from "assets/1.jpg";
import { Link, NavLink } from "react-router-dom";
import { IntroTexture } from "assets";
const { Title, Paragraph, Text } = Typography;

function Intro() {
  const screens = Grid.useBreakpoint();
  return (
    <Row
      id="intro"
      align="middle"
      justify="center"
      gutter={[8, 16]}
      style={{
        position: "relative",
        padding: screens.md ? "0 7.5rem" : "2rem",
        minHeight: screens.xs ? "auto" : "calc(100vh - 82px)",
        width: "100%",
        overflow: "hidden",
      }}
    >
      {screens.xl ? (
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            background: `url(${IntroTexture})`,
            transformOrigin: "100% 0",
            transform: "rotate(-60deg) translateY(-35%)",
            // zIndex: '-1',
          }}
        />
      ) : (
        ""
      )}
      <Col xs={24} md={11}>
        <Space direction="vertical" size="large">
          <Typography>
            <Title>Sân bóng Khoa Khôi</Title>
            <Paragraph
              style={{
                fontSize: "1.1rem",
              }}
            >
              Sân bóng Khoa Khôi kính chào quí khách, nơi thỏa mãn những đam mê
              thể thao.
            </Paragraph>
          </Typography>

          <Space>
            <Link to="/#service">
              <Button type="primary" shape="round" size="large">
                Dịch vụ
              </Button>
            </Link>

            <Link to="#contact">
              <Button shape="round" size="large">
                Tìm hiểu thêm
              </Button>
            </Link>
          </Space>
        </Space>
      </Col>
      <Col xs={24} md={13}>
        <Image
          width={"100%"}
          height={"auto"}
          src={IntroImg}
          preview={screens.xs ? false : true}
        />
      </Col>
    </Row>
  );
}

export default Intro;
