import React from "react";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Typography,
  Image,
  notification,
  Modal,
  Grid,
} from "antd";
import { useAuth } from "contexts/AuthContext";
import { Img1, Img2, Img3, Img4 } from "assets";
import locale from "antd/es/date-picker/locale/vi_VN";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TournamentBookingApi } from "api/tournamentBookingApi";
import { disabledDate } from "utils/helpers";
dayjs.extend(customParseFormat);

function Tournament() {
  const auth = useAuth();
  const screens = Grid.useBreakpoint();

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    notification.error({
      message: "Tạo giải đấu thất bại",
      description: "Đã có lỗi xảy ra, vui lòng thử lại!",
    });
  };

  const showConfirmModal = (values: any) => {
    Modal.confirm({
      title: "Xác nhận thông tin giải đấu",
      content: (
        <Space direction="vertical">
          <p>
            <b>Ngày bắt đầu: </b>{" "}
            {values.dates[0].format("DD-MM-YYYY") || "Chưa nhập"}
          </p>
          <p>
            <b>Ngày kết thúc: </b>{" "}
            {values.dates[1].format("DD-MM-YYYY") || "Chưa nhập"}
          </p>
          <p>
            <b>Thời điểm:</b> {values.period}
          </p>
          <p>
            <b>Tên người đặt:</b> {values.name || "Chưa nhập"}
          </p>
          <p>
            <b>Số điện thoại:</b> {values.phoneNumber || "Chưa nhập"}
          </p>
        </Space>
      ),
      async onOk() {
        try {
          await TournamentBookingApi().create({
            name: values.name,
            phoneNumber: values.phoneNumber,
            startDate: new Date(values.dates[0]),
            endDate: new Date(values.dates[1]),
            period: values.period,
            bookerId: auth.userInfor?.id!,
          });
          console.log("Booking success");

          Modal.success({
            title: "Tạo giải đấu thành công",
            content: (
              <>
                <p>
                  Quý khách đã tạo yêu cầu giải đấu thành công, sẽ có nhân viên
                  liên hệ với quý khách trong thời gian sớm nhất để xác nhận!
                  Quý khách vui lòng thanh toán trực tiếp tại văn phòng hoặc
                  chuyển khoản vào tài khoản của CLB bóng đá Khoa Khôi. Trân
                  trọng cảm ơn.
                </p>
                <p>
                  <b>Ngân hàng VP Bank</b>
                </p>
                <p>
                  <b>Số tài khoản:</b> 1965107637
                </p>
                <p>
                  <b>Chủ tài khoản:</b> Nguyễn Thường Hiếu
                </p>
                <p>
                  <b>Nội dung chuyển khoản:</b> Đăng ký giải đấu - {values.name}{" "}
                  - {values.phoneNumber}
                </p>
              </>
            ),
            width: 600,
          });
        } catch {
          notification.error({
            message: "Tạo giải đấu thất bại",
            description: "Đã có lỗi xảy ra, vui lòng thử lại!",
          });
          return console.log("Oops errors!");
        }
      },
    });
  };

  return (
    <Space
      direction="vertical"
      style={{
        padding: screens.xl ? "2rem 10rem" : "2.4rem",
        background: "white",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography.Title level={2}>Tổ chức giải đấu</Typography.Title>

      <Space
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          flexWrap: "wrap",
        }}
      >
        <Form
          name="tournament-form"
          layout="vertical"
          requiredMark={false}
          onFinish={showConfirmModal}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="dates"
            label="Chọn ngày"
            rules={[{ required: true, message: "Vui lòng chọn ngày!" }]}
          >
            <DatePicker.RangePicker
              format="DD-MM-YYYY"
              locale={locale}
              disabledDate={(current) =>
                current && current < dayjs().endOf("day")
              }
            />
          </Form.Item>

          <Form.Item
            name="period"
            label="Chọn thời gian"
            rules={[{ required: true, message: "Vui lòng chọn thời gian!" }]}
          >
            <Select placeholder="Chọn thời gian">
              <Select.Option value="Sáng">Buổi sáng</Select.Option>
              <Select.Option value="Chiều">Buổi chiều</Select.Option>
              <Select.Option value="Cả ngày">Cả ngày</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="name"
            label="Tên"
            rules={[
              { required: true, message: "Vui lòng nhập tên người đặt!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            label="Số điện thoại"
            rules={[
              {
                pattern: new RegExp(/(0[3|5|7|8|9])+([0-9]{8})\b/g),
                message: "Số điện thoại không hợp lệ!",
              },
              { required: true, message: "Vui lòng nhập số điện thoại!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Tạo giải đấu
            </Button>
          </Form.Item>
        </Form>

        <Card
          title="Thông tin sân"
          style={{ width: screens.lg ? 700 : "auto" }}
        >
          <Card.Grid style={{ width: screens.lg ? "50%" : "100%" }}>
            <Image src={Img1} />
          </Card.Grid>
          <Card.Grid style={{ width: screens.lg ? "50%" : "100%" }}>
            <Image src={Img2} />
          </Card.Grid>
          <Card.Grid style={{ width: screens.lg ? "50%" : "100%" }}>
            <Image src={Img3} />
          </Card.Grid>
          <Card.Grid style={{ width: screens.lg ? "50%" : "100%" }}>
            <Image src={Img4} />
          </Card.Grid>
        </Card>
      </Space>
    </Space>
  );
}

export default Tournament;
